const workHistory = [
  {
    company: 'Bittercreek Technologies, Inc',
    location: 'Salt Lake City, Ut',
    roleTitle: 'Jr Web Developer & Designer',
    dates: 'May 1, 2018 - May 31, 2019',
    description: [
      'Designed and created a dynamic website builder using ReactJS, Apollo, PostgreSQL, and Material-UI',
      'Added new features and improvements to legacy products to better serve customers’ needs.',
      'Created a Ruby script that translates XML to SQL requests.',
      'Worked within scrum team to implement new requirements.',
    ],
    relevancy: true,
    type: 'job',
  },
  {
    company: 'DevPoint Labs',
    location: 'Salt Lake City, Ut',
    roleTitle: 'Student',
    dates: 'February 2018 - April 2018',
    description: [
      'Full-Stack Web Development Certification through U of U Professional Education',

    ],
    relevancy: true,
    type: 'school',
  },
  {
    company: 'Michael\'s Arts & Crafts',
    location: 'West Valley City, Ut',
    roleTitle: 'Sales Floor Associate',
    dates: 'October 2015 - December 2018',
    description: [ ],
    relevancy: false,
    type: 'job',
  },
  {
    company: 'Louland Falls',
    location: 'Salt Lake City, Ut',
    roleTitle: 'Assistant Event Coordinator',
    dates: 'Summer & Fall 2015',
    description: [],
    relevancy: false,
    type: 'job',
  },
  {
    company: 'Beehive Clothing Mills',
    location: 'West Valley City, Ut',
    roleTitle: 'Piece Rate Sewing',
    dates: 'June 2012 - May 2013',
    description: [],
    relevancy: false,
    type: 'job',
  },
  {
    company: 'Northern Owl Creations',
    location: 'West Valley City, Ut',
    roleTitle: 'Assistant and Designer',
    dates: 'Winter 2012 - Fall 2015',
    description: [],
    relevancy: false,
    type: 'job',
  },
  {
    roleTitle: 'Student',
    dates: 'Graduated December 2011',
    company: 'BYU-Idaho',
    location: 'Rexburg, Idaho',
    degree: 'Bachelor of Arts',
    studyField: 'Integrated Studio Art',
    relevancy: true,
    type: 'school',
  },
  {
    roleTitle: 'Student',
    dates: 'Graduated June 2009',
    company: 'Salt Lake Community College',
    location: 'Taylorsville, Ut',
    degree: 'Associate of Science',
    studyField: 'General Studies',
    relevancy: true,
    type: 'school',
  },
];

export default workHistory;